import { Button, Dialog, Divider, FormControlLabel, IconButton, Switch, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";
import AutocompleteWarehouses from "elements/AutocompleteWarehouses";
import AutocompleteField from "elements/AutocompleteField";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { ApiRequest } from "GlobalFunctions";
import { min } from "lodash";
import AdvancedOrderEditor from "./AdvancedOrderEditor";

export default function OrdersEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, order_id, loadList, advancedOrderEditor } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    order_id: "",
    post_date: "",
    comments: "",
    products: [] as any,
    client_id: "",
    longitude: "",
    latitude: "",
    subtotal: 0,
    taxes: 0,
    total: 0,
  };

  const defaultAdvancedValues = {
    product_id: null,
    product_info: {},
    square_meters: '',
    boxes: '',
    discount: '',
    units: '',
    quantity_text: '',
    calculated_subtotal: 0,
    calculated_tax: 0,
    calculated_total: 0,
  };

  useEffect(() => {
    if (openEditor) {
      setFormValues(defaultValues);
      if (order_id) {
        loadDetails();
      }
    }
  }, [openEditor, order_id]);

  const handleClose = () => {
    setFormValues(defaultValues);
    setOpenEditor(false);
    setIsEditable(false); // Set isEditable to false when closing
  };

  const loadDetails = async () => {
    ApiRequest({
      url: "/orders/details",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        order_id,
      },
      setResponse: (response: any) => {
        setFormValues(response.data);
      },
    });
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (order_id) {
      setIsEditable(false);
    } else {
      setIsEditable(true); // Set isEditable to true for new orders
    }
  }, [order_id]);

  useEffect(() => {
    if (order_id) {
      loadDetails();
    } else {
      setFormValues(defaultValues);
    }
  }, [order_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const [list, setList] = useState<any[]>([]);
  /*=========================================================
  GET LIST
  =========================================================*/
  const getList = async () => {
    await ApiRequest({
      url: "/products/list",
      method: "get",
      headers: {
        ltkn: localStorage.getItem('ltkn')
      },
      query: {
        full_list: true,
      },
      setResponse: (response: any) => {
        setList(response.list);
      },
    });
  };

  useEffect(() => {
    getList();
  }, []);

  /*===============================================================
  SUBMIT FORM
  ===============================================================*/
  const handleSubmit = async () => {
    // Check if a client is selected
    if (!formValues.client_id) {
      return enqueueSnackbar("Debe seleccionar un cliente", { variant: "error" });
    }

    // Check if products are added
    if (formValues.products.length === 0) {
      return enqueueSnackbar("Debe agregar al menos un producto", { variant: "error" });
    }

    // Validation depends on which editor is being used
    if (advancedOrderEditor) {
      // Advanced editor validation
      if (formValues.products.some((product: any) => !product.product_info?.product_id)) {
        return enqueueSnackbar("Seleccione un producto", { variant: "error" });
      }
      
      const invalidProducts = formValues.products.filter((product: any) => {
        const isMeterProduct = product.product_info?.product_measure_type === 'm2';
        if (isMeterProduct) {
          return !product.boxes || Number(product.boxes) <= 0;
        } else {
          return !product.units || Number(product.units) <= 0;
        }
      });
      
      if (invalidProducts.length > 0) {
        return enqueueSnackbar(
          "Todos los productos deben tener una cantidad válida (cajas para productos de m² o unidades para productos regulares)", 
          { variant: "error" }
        );
      }
    } else {
      // Standard editor validation
      if (formValues.products.some((product: any) => !product.product_info?.product_id)) {
        return enqueueSnackbar("Seleccione un producto", { variant: "error" });
      }

      if (formValues.products.some((product: any) => {
        return (!product.quantity || Number(product.quantity) <= 0) && 
               (!product.replacement_quantity || Number(product.replacement_quantity) <= 0);
      })) {
        return enqueueSnackbar("Ingrese la cantidad de productos o el cambio", { variant: "error" });
      }
    }

    setLoading(true);
    
    // Make a copy of the formValues for submission to avoid modifying state directly
    let submissionValues = { ...formValues };

    // Ensure all products have proper calculation values
    if (advancedOrderEditor) {
      // Force recalculate all products to ensure fresh values
      submissionValues.products = formValues.products.map((product: any) => {
        const basePrice = Number(product.product_info?.client_product_price || 0);
        const taxRate = Number(product.product_info?.tax_1 || 0) / 100;
        const discountRate = product.discount && Number(product.discount) > 0 ? Number(product.discount) / 100 : 0;
        
        let subtotal = 0;
        let quantityText = '';
        
        if (product.product_info?.product_measure_type === 'm2') {
          const conversion = Number(product.product_info?.product_conversion || 1);
          const boxes = Number(product.boxes || 0);
          const calculatedSquareMeters = boxes * conversion;
          subtotal = basePrice * calculatedSquareMeters;
          quantityText = `${calculatedSquareMeters.toFixed(2)} m²`;
        } else {
          const units = Number(product.units || 0);
          subtotal = basePrice * units;
          quantityText = `${units} U.`;
        }
        
        const discountAmount = subtotal * discountRate;
        const priceAfterDiscount = subtotal - discountAmount;
        const taxAmount = priceAfterDiscount * taxRate;
        const total = priceAfterDiscount + taxAmount;
        
        return {
          ...product,
          quantity_text: quantityText,
          calculated_subtotal: subtotal,
          calculated_tax: taxAmount,
          calculated_total: total
        };
      });
      
      // Calculate totals for the order
      submissionValues.subtotal = submissionValues.products.reduce(
        (sum: any, item: any) => sum + (item.calculated_subtotal || 0), 
        0
      );
      
      submissionValues.taxes = submissionValues.products.reduce(
        (sum: any, item: any) => sum + (item.calculated_tax || 0), 
        0
      );
      
      submissionValues.total = submissionValues.products.reduce(
        (sum: any, item: any) => sum + (item.calculated_total || 0), 
        0
      );
    } else {
      // Regular order calculations
      submissionValues.subtotal = subtotal;
      submissionValues.taxes = taxes;
      submissionValues.total = total;
    }

    const body = new FormData();
    body.append("formValues", JSON.stringify(submissionValues));
    
    console.log("Submitting formValues:", submissionValues);

    await ApiRequest({
      url: "/orders/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar("Guardado con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  const productDefaultStandard = {
    product_id: null,
    package_quantity: "",
    units: "",
    quantity: 0,
    replacement_quantity: 0,
    client_inventory: 0,
  };

  const addProduct = () => {
    const newProduct = advancedOrderEditor ? defaultAdvancedValues : productDefaultStandard;
    let newProducts = [...formValues.products, newProduct];
    setFormValues({ ...formValues, products: newProducts });
  };

  const getReport = async (download = "") => {
    console.log(formValues);
    setLoading(true);

    await ApiRequest({
      url: "/reports/getReport",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        order_id,
        exportType: "getMovement",
        download,
      },
      setResponse: (response: any) => {
        if (response.status === "success") {
          if (download === "pdf") {
            window.open(response.pdfUrl, "_blank");
          }
          if (download === "excel") {
            window.open(response.fileUrl, "_blank");
          }
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  // Add state variables for totals
  const [totalProducts, setTotalProducts] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // Calculate totals whenever products change
    calculateTotals();
  }, [formValues.products]);

  const calculateTotals = () => {
    if (advancedOrderEditor) {
      // For advanced editor, sum up the calculated values from each product
      let subtotalSum = 0;
      let taxesSum = 0;
      let totalSum = 0;
      
      formValues.products.forEach((item: any) => {
        // Calculate fresh values to ensure accuracy
        const basePrice = Number(item.product_info?.client_product_price || 0);
        const taxRate = Number(item.product_info?.tax_1 || 0) / 100;
        const discountRate = item.discount && Number(item.discount) > 0 ? Number(item.discount) / 100 : 0;
        
        let itemSubtotal = 0;
        
        if (item.product_info?.product_measure_type === 'm2') {
          const conversion = Number(item.product_info?.product_conversion || 1);
          const boxes = Number(item.boxes || 0);
          const calculatedSquareMeters = boxes * conversion;
          itemSubtotal = basePrice * calculatedSquareMeters;
        } else {
          const units = Number(item.units || 0);
          itemSubtotal = basePrice * units;
        }
        
        const discountAmount = itemSubtotal * discountRate;
        const priceAfterDiscount = itemSubtotal - discountAmount;
        const taxAmount = priceAfterDiscount * taxRate;
        const itemTotal = priceAfterDiscount + taxAmount;
        
        subtotalSum += itemSubtotal;
        taxesSum += taxAmount;
        totalSum += itemTotal;
      });
      
      setSubtotal(subtotalSum);
      setTaxes(taxesSum);
      setTotal(totalSum);
    } else {
      // For standard editor, calculate based on quantities and prices
      let totalProducts = formValues.products.length;
      let subtotalSum = 0;
      let taxesSum = 0;
      
      formValues.products.forEach((item: any) => {
        const price = Number(item.product_info?.client_product_price || 0);
        const quantity = Number(item.quantity || 0);
        const taxRate = Number(item.product_info?.tax_1 || 0) / 100;
        
        const itemSubtotal = price * quantity;
        const itemTax = itemSubtotal * taxRate;
        
        subtotalSum += itemSubtotal;
        taxesSum += itemTax;
      });
      
      let totalSum = subtotalSum + taxesSum;
      
      setTotalProducts(totalProducts);
      setSubtotal(subtotalSum);
      setTaxes(taxesSum);
      setTotal(totalSum);
    }
  };

  // Get max discount from restrictions
  const [restrictions, setRestrictions] = useState<any>({});
  const maxDiscount = restrictions?.max_discount !== undefined ? restrictions.max_discount : 100;

  useEffect(() => {
    const getRestrictions = async () => {
      await ApiRequest({
        url: "/companies/restrictions",
        method: "get",
        headers: {
          ltkn: localStorage.getItem('ltkn')
        },
        setResponse: (response: any) => {
          setRestrictions(response);
        },
      });
    };
    getRestrictions();
  }, []);

  return (
    <Dialog open={openEditor} onClose={handleClose} maxWidth="lg" fullWidth>
      <div className="DialogContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Pedido</h2>
              <span className="h2_subtitle">Editar</span>
            </div>

            <FormControlLabel
              control={
                <Switch
                  checked={isEditable}
                  onChange={() => setIsEditable(!isEditable)}
                  color="primary"
                />
              }
              label="Editar"
            />
          </div>

          <div className="input_group">
            <AutocompleteField
              label="Cliente"
              endpoint="/clients/list"
              value={formValues.client_id}
              onChange={(data: any) => {
                setFormValues({ ...formValues, client_id: data.value });
              }}
              disabled={!!order_id || !isEditable}
              fullwidth={true}
              sx={{ minWidth: "500px" }}
            />

            <TextField
              id="post_date"
              name="post_date"
              label="Post fechar"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formValues.post_date}
              onChange={handleInputChange}
              disabled={!isEditable}
            />

            <TextField
              id="comments"
              name="comments"
              label="Comentarios"
              multiline
              rows={4}
              fullWidth
              value={formValues.comments}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </div>

          {advancedOrderEditor ? (
            <AdvancedOrderEditor 
              formValues={formValues}
              setFormValues={setFormValues}
              isEditable={isEditable}
              list={list}
              maxDiscount={maxDiscount}
            />
          ) : (
            <div className="productList">
              <h3>Productos</h3>
              <div className="productListItems">
                {formValues.products.map((product: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <div className="productListItem">
                        <AutocompleteField
                          label="Producto"
                          endpoint="/products/list"
                          endpoint_params={{ client_id: formValues.client_id }}
                          renderValues={["product_code", "label"]}
                          value={product.product_info.product_id}
                          preloadedList={list}
                          onChange={(data: any) => {
                            let newProducts = [...formValues.products];
                            newProducts[index] = {
                              ...newProducts[index],
                              product_info: data,
                            };
                            setFormValues({ ...formValues, products: newProducts });
                          }}
                          disabled={!isEditable}
                        />
                        <TextField
                          id="quantity"
                          name="quantity"
                          label="Cantidad"
                          type="number"
                          required
                          value={product.quantity}
                          onChange={(e) => {
                            let newProducts = [...formValues.products];
                            newProducts[index] = {
                              ...newProducts[index],
                              quantity: e.target.value,
                            };
                            setFormValues({ ...formValues, products: newProducts });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              addProduct();
                            }
                          }}
                          disabled={!isEditable}
                        />
                        <TextField
                          id="replacement_quantity"
                          name="replacement_quantity"
                          label="Cambio"
                          type="number"
                          value={product.replacement_quantity}
                          onChange={(e) => {
                            let newProducts = [...formValues.products];
                            newProducts[index] = {
                              ...newProducts[index],
                              replacement_quantity: e.target.value,
                            };
                            setFormValues({ ...formValues, products: newProducts });
                          }}
                          disabled={!isEditable}
                        />
                        <TextField
                          id="client_inventory"
                          name="client_inventory"
                          label="Inventario"
                          type="number"
                          value={product.client_inventory}
                          onChange={(e) => {
                            let newProducts = [...formValues.products];
                            newProducts[index] = {
                              ...newProducts[index],
                              client_inventory: e.target.value,
                            };
                            setFormValues({ ...formValues, products: newProducts });
                          }}
                          disabled={!isEditable}
                        />
                        <DeleteOutlineOutlinedIcon
                          color={isEditable ? 'primary' : 'disabled'}
                          onClick={() => {
                            if (isEditable) {
                              let newProducts = formValues.products.filter((_: any, i: any) => i !== index);
                              setFormValues({ ...formValues, products: newProducts });
                            }
                          }}
                          style={{ cursor: isEditable ? 'pointer' : 'not-allowed' }}
                        />
                      </div>

                      <div className="priceRow">
                        <div className="priceRowItem">
                          <span className="priceRowLabel">Precio</span>
                          <span className="priceRowValue">
                            ${Number(product.product_info?.client_product_price || 0).toLocaleString("es-CO", { maximumFractionDigits: 0 })}
                          </span>
                        </div>
                        <div className="priceRowItem">
                          <span className="priceRowLabel">Impuestos:</span>
                          <span className="priceRowValue">
                            ${Number(product.product_info?.taxes || 0).toLocaleString("es-CO", { maximumFractionDigits: 0 })}
                          </span>
                        </div>
                        <div className="priceRowItem">
                          <span className="priceRowLabel">Total:</span>
                          <span className="priceRowValue">
                            ${Number(
                              Math.round(
                                (product.product_info?.client_product_price || 0) +
                                (product.product_info?.taxes || 0)
                              ) *
                              (product.quantity || 0)
                            ).toLocaleString("es-CO", { maximumFractionDigits: 0 })}
                          </span>
                        </div>
                      </div>
                      <Divider />
                    </Fragment>
                  );
                })}
                <div className="addProductButton">
                  <IconButton
                    aria-label="add"
                    onClick={addProduct}
                    sx={{
                      backgroundColor: "#1C3B41",
                      padding: '10px',
                      color: '#fff',
                      '&:hover': { backgroundColor: '#24545d' }
                    }}
                    disabled={!isEditable}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          )}

          <div className="totalRow">
            <span className="totalRowItem">{formValues.products.length} Productos</span>
            <span className="totalRowItem"><b>Subtotal:</b> ${Number(subtotal).toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
            <span className="totalRowItem"><b>Impuestos:</b> ${Number(taxes).toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
            <span className="totalRowItem"><b>Total a pagar:</b> ${Number(Math.round(total)).toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
          </div>

          <div className="dialogButtons">
            <Button onClick={handleClose} variant="outlined">
              Cerrar
            </Button>
            <Button onClick={handleSubmit} variant="contained" disabled={!isEditable}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
