import { Chip, IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Fragment } from "react";

export default function FiltersApplied(props: any) {

  return (
    <Fragment>
        {/* SHOWS ONLY IF PROPS.FILTERSVALUES EXISTS AND IS NOT EMPTY */}
    {Object.keys(props.filtersValues).length > 0 && (
        <div className="filtersApplied">
            {Object.keys(props.filtersValues).map((key, index) => {
                const filter = props.filtersValues[key];
                const label = filter.label + ': ' + (filter.value_txt ?? filter.value);

                return (
                    <Chip 
                        size="small" 
                        key={index} 
                        label={label}
                        onDelete={() => {
                            delete props.filtersValues[key];
                            props.setFiltersValues({...props.filtersValues});
                        }}
                    />
                )
            })}

        </div>
    )
    }
    </Fragment>
  );
}
