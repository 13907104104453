import { Button, Dialog, IconButton, TextField, Typography, Box, Divider, Chip } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState, useRef } from "react";
import AutocompleteField from "elements/AutocompleteField";
import './AdvancedOrderEditor.css';

export default function AdvancedOrderEditor(props: any) {
  const { formValues, setFormValues, isEditable, list, maxDiscount = 100 } = props;
  // Keep a stable reference to the current products to avoid loops
  const productsRef = useRef<any[]>([]);

  // Update the reference whenever formValues.products changes
  useEffect(() => {
    productsRef.current = formValues.products;
  }, [formValues.products]);

  const defaultAdvancedValues = {
    product_id: null,
    product_info: {},
    square_meters: '',
    boxes: '',
    discount: '',
    units: '',
    quantity_text: '',
    calculated_subtotal: 0,
    calculated_tax: 0,
    calculated_total: 0,
  };

  // Add a product to the order
  const addProduct = () => {
    let newProducts = [...formValues.products, {...defaultAdvancedValues}];
    setFormValues({ ...formValues, products: newProducts });
  };

  // Calculate prices for a product
  const calculatePrices = (product: any) => {
    // Guard against undefined product_info
    if (!product?.product_info) return {
      basePrice: 0,
      subtotal: 0,
      discountAmount: 0,
      taxAmount: 0,
      total: 0,
      quantityText: ''
    };

    const basePrice = Number(product.product_info?.client_product_price || 0);
    const taxRate = Number(product.product_info?.tax_1 || 0) / 100;
    const discountRate = product.discount && Number(product.discount) > 0 ? Number(product.discount) / 100 : 0;
    
    let subtotal = 0;
    let quantityText = '';
    
    if (product.product_info?.product_measure_type === 'm2') {
      // For m2 products, calculate based on boxes converted to square meters
      const conversion = Number(product.product_info?.product_conversion || 1);
      const boxes = Number(product.boxes || 0);
      const calculatedSquareMeters = boxes * conversion;
      subtotal = basePrice * calculatedSquareMeters;
      quantityText = `${calculatedSquareMeters.toFixed(2)} m²`;
    } else {
      // Default to 'unidad' for any other measure type or undefined
      const units = Number(product.units || 0);
      subtotal = basePrice * units;
      quantityText = `${units} U.`;
    }

    const discountAmount = subtotal * discountRate;
    const priceAfterDiscount = subtotal - discountAmount;
    const taxAmount = priceAfterDiscount * taxRate;
    const total = priceAfterDiscount + taxAmount;

    return {
      basePrice,
      subtotal,
      discountAmount,
      taxAmount,
      total,
      quantityText
    };
  };



  // Calculate conversion values
  const calculateConvertedValue = (squareMeters: any, conversion: any) => {
    if (!squareMeters) return { value: '0 Cajas', color: '#4CAF50' };
    const value = parseFloat(squareMeters) / Number(conversion || 1);
    const isInteger = Number.isInteger(value);
    return {
      value: `${value.toFixed(2)} Cajas`,
      color: isInteger ? '#4CAF50' : '#F44336'
    };
  };

  const calculateBoxesValue = (boxes: any, conversion: any) => {
    if (!boxes) return { value: '0 m²', color: '#4CAF50' };
    const value = parseFloat(boxes) * Number(conversion || 1);
    return {
      value: `${value.toFixed(2)} m²`,
      color: '#4CAF50'
    };
  };

  // Run calculations when component mounts
  useEffect(() => {
    if (formValues.products?.length > 0) {
      let needsUpdate = false;
      const updatedProducts = [...formValues.products];
      
      formValues.products.forEach((product: any, index: any) => {
        if (product.product_info?.client_product_price) {
          const calculations = calculatePrices(product);
          if (product.calculated_subtotal !== calculations.subtotal) {
            needsUpdate = true;
            updatedProducts[index] = {
              ...updatedProducts[index],
              quantity_text: calculations.quantityText,
              calculated_subtotal: calculations.subtotal,
              calculated_tax: calculations.taxAmount,
              calculated_total: calculations.total
            };
          }
        }
      });
      
      // Only update if calculations changed
      if (needsUpdate) {
        setFormValues({...formValues, products: updatedProducts});
      }
    }
  }, []);

  return (
    <div className="productList">
      <h3>Productos</h3>
      <div className="productListItems">
        {formValues.products.map((product: any, index: any) => {
          const calculations = calculatePrices(product);
          const conversion = product.product_info?.product_conversion || 1;
          // Important: Store the product measure type in a variable to ensure consistency
          const isMeterProduct = product.product_info?.product_measure_type === 'm2';
          
          return (
            <div key={index} className="advancedProductItem">
              <div className="productListItem">
                <AutocompleteField
                  label="Producto"
                  endpoint="/products/list"
                  fullwidth={true}
                  endpoint_params={{ client_id: formValues.client_id }}
                  renderValues={["product_code", "label"]}
                  value={product.product_info?.product_id}
                  preloadedList={list}
                  onChange={(data: any) => {
                    // First, create a copy of the products array
                    const newProducts = [...formValues.products];
                    
                    // Replace the product at the current index with the default values and new product_info
                    newProducts[index] = {
                      ...defaultAdvancedValues,
                      product_info: data
                    };
                    
                    // Update the form state
                    setFormValues({
                      ...formValues,
                      products: newProducts
                    });
                  }}
                  disabled={!isEditable}
                />
                
                {isMeterProduct ? (
                  // For m2 products, show boxes and square meters inputs
                  <>
                    <TextField
                      label="Cajas"
                      type="text"
                      value={product.boxes || ""}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const newProducts = [...formValues.products];
                        
                        // Update the current product
                        newProducts[index] = { 
                          ...newProducts[index], 
                          boxes: inputValue,
                          square_meters: inputValue ? (Number(inputValue) * conversion).toFixed(2) : ''
                        };
                        
                        // Update state
                        setFormValues({
                          ...formValues,
                          products: newProducts
                        });
                        
                      }}
                      disabled={!isEditable}
                      InputProps={{
                        endAdornment: (
                          <Chip 
                            label={calculateBoxesValue(product.boxes, conversion).value}
                            size="small"
                            style={{ 
                              backgroundColor: '#f0f0f0', 
                              color: calculateBoxesValue(product.boxes, conversion).color 
                            }}
                          />
                        )
                      }}
                    />
                    <TextField
                      label="Mt2"
                      type="text"
                      value={product.square_meters || ""}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const newProducts = [...formValues.products];
                        
                        // Update only the square_meters field without changing boxes
                        newProducts[index] = { 
                          ...newProducts[index], 
                          square_meters: inputValue
                        };
                        
                        // Update state
                        setFormValues({
                          ...formValues,
                          products: newProducts
                        });
                      }}
                      disabled={!isEditable}
                      InputProps={{
                        endAdornment: (
                          <Chip
                            label={calculateConvertedValue(product.square_meters, conversion).value}
                            size="small"
                            style={{ 
                              backgroundColor: '#f0f0f0', 
                              color: calculateConvertedValue(product.square_meters, conversion).color 
                            }}
                          />
                        )
                      }}
                    />
                  </>
                ) : (
                  // For non-m2 products, show only units input
                  <TextField
                    label="Unidades"
                    type="text"
                    value={product.units || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const newProducts = [...formValues.products];
                      
                      // Update the current product
                      newProducts[index] = { 
                        ...newProducts[index], 
                        units: inputValue
                      };
                      
                      // Update state
                      setFormValues({
                        ...formValues,
                        products: newProducts
                      });
                      
                      // Recalculate
                    //   setTimeout(() => updateCalculatedValues(newProducts[index], index), 100);
                    }}
                    disabled={!isEditable}
                  />
                )}
                
                <TextField
                  label={`Descuento % (max: ${maxDiscount}%)`}
                  type="text"
                  value={product.discount || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numValue = Number(inputValue);
                    const newProducts = [...formValues.products];
                    
                    // Update the current product
                    newProducts[index] = { 
                      ...newProducts[index], 
                      discount: inputValue,
                      discount_error: numValue > maxDiscount ? `El descuento máximo permitido es ${maxDiscount}%` : ""
                    };
                    
                    // Update state
                    setFormValues({
                      ...formValues,
                      products: newProducts
                    });
                    
                    // Recalculate
                    // setTimeout(() => updateCalculatedValues(newProducts[index], index), 100);
                  }}
                  error={Number(product.discount) > maxDiscount}
                  helperText={product.discount_error}
                  disabled={!isEditable}
                />
                
                <IconButton 
                  aria-label="delete" 
                  onClick={() => {
                    if (isEditable) {
                      let newProducts = formValues.products.filter((_: any, i: any) => i !== index);
                      setFormValues({ ...formValues, products: newProducts });
                    }
                  }}
                  disabled={!isEditable}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>

              {isMeterProduct && product.product_info && (
                <div className="productSpecs">
                  <Typography variant="body2">
                    <strong>Metros cuadrados por caja:</strong> {product.product_info.product_conversion || 1}
                  </Typography>
                  {product.product_info.product_package && (
                    <Typography variant="body2">
                      <strong>Unidades por caja:</strong> {product.product_info.product_package}
                    </Typography>
                  )}
                </div>
              )}

              <Box className="priceCalculations">
                <Typography variant="body1" className="priceItem">
                  <span>Precio base: </span>
                  <span>${calculations.basePrice.toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
                </Typography>
                <Typography variant="body1" className="priceItem">
                  <span>Subtotal: </span>
                  <span>${calculations.subtotal.toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
                </Typography>
                {product.discount && Number(product.discount) > 0 && (
                  <Typography variant="body1" className="priceItem discount">
                    <span>Descuento ({product.discount}%): </span>
                    <span>-${calculations.discountAmount.toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
                  </Typography>
                )}
                <Typography variant="body1" className="priceItem">
                  <span>Impuestos: </span>
                  <span>${calculations.taxAmount.toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
                </Typography>
                <Typography variant="body1" className="priceItem total">
                  <span>Total: </span>
                  <span>${calculations.total.toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
                </Typography>
                {calculations.quantityText && (
                  <Typography variant="body2" style={{ marginTop: 8, fontStyle: 'italic' }}>
                    Cantidad: {calculations.quantityText}
                  </Typography>
                )}
              </Box>
              
              <Divider style={{ margin: "15px 0" }} />
            </div>
          );
        })}
        
        <div className="addProductButton">
          <IconButton
            aria-label="add"
            onClick={addProduct}
            sx={{
              backgroundColor: "#1C3B41",
              padding: '10px',
              color: '#fff',
              '&:hover': { backgroundColor: '#24545d' }
            }}
            disabled={!isEditable}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
