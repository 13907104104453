import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {ProjectConfig} from 'Global';
import AutocompleteField from "elements/AutocompleteField";
import { ApiRequest } from "GlobalFunctions";

export default function ClientsEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, client_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();



  const defaultValues = {
    client_id: client_id ? client_id : "null",
    client_code: "",
    client_name: "",
    client_name2: "",
    client_last_name: "",
    client_last_name2: "",
    client_phone: "",
    client_address: "",
    city_id: "",
    client_email: "",
    business_name: "",
    document: "",
    neighborhood_id: "",
    typology_id: "",
    price_table: "",
    longitude: "",
    latitude: "",
    payment_method: "",
    paydays: "",
    credit: "",
    client_created_at: "",
    status: true,
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (client_id) {
      axios.get(`${ProjectConfig.api_url}/clients/details`, {
        params: { client_id },
      })
      .then((response) => {
        console.log(response.data);
        setFormValues(response.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
    } else {
      setFormValues(defaultValues);
    }
  }, [client_id]);

  const handleInputChange = (e: { target: { name: any; value: any }; }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);

    await ApiRequest({
      url: "/clients/addedit",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      formData: true,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Marca guardada con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
          
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        
      },
    });
  };


  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)} fullWidth maxWidth="md">
      <div className="DialogContainer" >
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Cliente</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <h2>Información básica</h2>
            <div className='input_row'>
              <TextField
                id="client_code"
                name="client_code"
                label="Código"
                type="text"
                required
                fullWidth
                value={formValues.client_code}
                onChange={handleInputChange}
              />
              <TextField
                id="document"
                name="document"
                label="Documento"
                type="text"
                required
                fullWidth
                value={formValues.document}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                id="client_name"
                name="client_name"
                label="Nombre"
                type="text"
                required
                fullWidth
                value={formValues.client_name}
                onChange={handleInputChange}
              />
              <TextField
                id="client_name2"
                name="client_name2"
                label="Segundo nombre"
                type="text"
                fullWidth
                value={formValues.client_name2}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                id="client_last_name"
                name="client_last_name"
                label="Apellido"
                type="text"
                required
                fullWidth
                value={formValues.client_last_name}
                onChange={handleInputChange}
              />
              <TextField
                id="client_last_name2"
                name="client_last_name2"
                label="Segundo apellido"
                type="text"
                fullWidth
                value={formValues.client_last_name2}
                onChange={handleInputChange}
              />
            </div>

            <TextField
              id="business_name"
              name="business_name"
              label="Nombre del negocio"
              type="text"
              required
              fullWidth
              value={formValues.business_name}
              onChange={handleInputChange}
            />
          </div>
            
          <div className='input_group'>
            <h2>Información de contacto</h2>
            <div className='input_row'>
              <TextField
                id="client_phone"
                name="client_phone"
                label="Teléfono"
                type="text"
                required
                fullWidth
                value={formValues.client_phone}
                onChange={handleInputChange}
              />
              <TextField
                id="client_email"
                name="client_email"
                label="Email"
                type="text"
                fullWidth
                value={formValues.client_email}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='input_group'>
            <h2>Ubicación</h2>
            <TextField
              id="client_address"
              name="client_address"
              label="Dirección"
              type="text"
              required
              fullWidth
              value={formValues.client_address}
              onChange={handleInputChange}
            />

            <div className='input_row'>
              <AutocompleteField
                label="Ciudad"
                endpoint="/cities/list"
                fullwidth
                value={formValues.city_id}
                onChange={(data: any) => setFormValues({ ...formValues, city_id: data.value })}
              />
              <AutocompleteField 
                label="Barrio"
                fullwidth
                endpoint="/neighborhoods/list"
                value={formValues.neighborhood_id}
                onChange={(data: any) => setFormValues({ ...formValues, neighborhood_id: data.value })}
              />
            </div>

            <div className='input_row'>
              <TextField
                id="longitude"
                name="longitude"
                label="Longitud"
                type="text"
                required
                fullWidth
                value={formValues.longitude}
                onChange={handleInputChange}
              />
              <TextField
                id="latitude"
                name="latitude"
                label="Latitud"
                type="text"
                required
                fullWidth
                value={formValues.latitude}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className='input_group'>
            <h2>Información comercial</h2>
            <div className='input_row'>
              <AutocompleteField 
                label="Tipología"
                fullwidth
                endpoint="/typology/list"
                value={formValues.typology_id}
                onChange={(data: any) => setFormValues({ ...formValues, typology_id: data.value })}
              />
              <TextField
                id="price_table"
                name="price_table"
                label="Tabla de precios"
                type="text"
                required
                fullWidth
                value={formValues.price_table}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                id="payment_method"
                name="payment_method"
                label="Método de pago"
                type="text"
                required
                fullWidth
                value={formValues.payment_method}
                onChange={handleInputChange}
              />
              <TextField
                id="paydays"
                name="paydays"
                label="Días de pago"
                type="text"
                required
                fullWidth
                value={formValues.paydays}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                id="credit"
                name="credit"
                label="Crédito"
                type="text"
                required
                fullWidth
                value={formValues.credit}
                onChange={handleInputChange}
              />
              <TextField
                id="status"
                name="status"
                label="Estado"
                type="text"
                required
                fullWidth
                value={formValues.status}
                onChange={handleInputChange}
              />
            </div>

            <TextField
              id="client_created_at"
              name="client_created_at"
              label="Fecha de creación"
              type="text"
              required
              fullWidth
              value={formValues.client_created_at}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">Cancelar</Button>
            <Button onClick={handleSubmit} variant="contained">Guardar</Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
