import { Chip, IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Fragment } from "react";

export default function FiltersApplied(props: any) {

    console.log(props.filtersValues);

  return (
    <Fragment>
        {/* SHOWS ONLY IF PROPS.FILTERSVALUES EXISTS AND IS NOT EMPTY */}
    {Object.keys(props.filtersValues).length > 0 && (
        <div className="filtersApplied">
            {Object.keys(props.filtersValues).map((key, index) => {
                //if value is longer than 10 characters, cut it
                let value = "Filtrado"

                return (
                    <Chip size="small" key={index} label={key} onDelete={() => {
                        delete props.filtersValues[key];
                                props.setFiltersValues({...props.filtersValues});
                            }
                        } />
                )
            })}

        </div>
    )
    }
    </Fragment>
  );
}
