import { Button, Chip, Dialog, FormControl, FormControlLabel, InputLabel, MenuItem, Select, styled, Switch, SwitchProps, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import AutocompleteField from "elements/AutocompleteField";
import { ApiRequest } from "GlobalFunctions";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";



export default function UsersEditor(props : any) {

  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    user_id,
    loadList,
    company_id = null,
  } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */



  const defaultValues: any = {
    user_id: user_id? user_id : "null",
    name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    is_active: true,
    role_id: 2, 
    company_id: company_id,
    zones: [],
    restrictions: {},
    roles: [],
    warehouses: [], // Añadido warehouses array
  };
  
  const [formValues, setFormValues] = useState(defaultValues)
  const [roles, setRoles] = useState([]);
  const [zones, setZones] = useState([]);


  const warehouseDefault = {
    warehouse_id: null,
    priority: ""
  }

  useEffect(() => {
    if(openEditor && !user_id){
      setFormValues({
        ...defaultValues,
        warehouses: []
      });
    }
  }, [openEditor]);

  const addWarehouse = () => {
    const newWarehouses = [...(formValues.warehouses || [])]; // Handle case when warehouses is undefined
    newWarehouses.push(warehouseDefault);
    setFormValues({
      ...formValues,
      warehouses: newWarehouses
    });
    console.log("Added warehouse", newWarehouses);
  }

  /*=======================================
  ROLES
  =======================================*/
  useEffect(() => {
    if(openEditor){
      getRoles();
    }
  }
  , [openEditor]);

  const getRoles = () => {
    axios.get(ProjectConfig.api_url + "/roles/list", 
    {
      headers: {
        "ltkn": localStorage.getItem('ltkn')
      }
    })
    .then((response) => {
      setRoles(response.data);
    })
    .catch((err) => {
      console.log(err.response.data);
    });
  }

  /*=======================================
  ZONES
  =======================================*/
  useEffect(() => {
    if(openEditor){
      getZones();
    }
  } , [openEditor]);

  const getZones = () => {
    axios.get(ProjectConfig.api_url + "/zones/list",
    {
      headers: {
        "ltkn": localStorage.getItem('ltkn')
      },
      params: {
        full_list: true
      }
    })
    .then((response) => {
      console.log("zones", response.data);
      setZones(response.data.list);
    })
    .catch((err) => {
      console.log(err.response.data);
    });
  }


  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
    if (user_id) {
      axios.get(ProjectConfig.api_url + "/users/details", {
          headers: {
            "ltkn": localStorage.getItem('ltkn')
          },
          params: {
            user_id: user_id,
          },
        })
        .then((response) => {
          console.log("formValues res", response.data)
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
    else{
      setFormValues(defaultValues);
    }
    
  } , [user_id])




  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {  
    setLoading(true);

    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);


    await ApiRequest({
      url: "/users/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('Usuario guardado con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };






  return (
      <>
        <Dialog 
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="DialogContainer" style={{maxWidth:'502px'}}>
              <div className="ModuleWrapper">
                  <div className="boxContainerHeader">
                      <div className="boxContainerTitle">
                          <h2 className="h2_title">Usuario</h2>
                          <span className="h2_subtitle">Editar</span>
                      </div>

                      
                  </div>

                  <div id="userForm">
                    <div className="userForm_right">
                      
                        <div className='input_group'>
                            <h2>Información Personal</h2>
                            <div className='input_group'>
                                <div className='input_row'>
                                <TextField 
                                  id="name"
                                  name="name"
                                  label="Nombres"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.name}
                                  onChange={handleInputChange}
                                />

                                <TextField
                                  id="last_name"
                                  name="last_name"
                                  label="Apellidos"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.last_name}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <TextField
                                id="phone"
                                name="phone"
                                label="Teléfono"
                                type="text"
                                required
                                fullWidth
                                value={formValues.phone}
                                onChange={handleInputChange}
                              />

                            </div>

                            <div className='input_group'>
                              <h2>Información de sesión</h2>
                              <div className="input_row">
                                <TextField
                                  id="email"
                                  name="email"
                                  label="Email"
                                  type="text"
                                  required
                                  fullWidth
                                  value={formValues.email}
                                  onChange={handleInputChange}
                                />

                                <TextField
                                  id="password"
                                  name="password"
                                  label="Password"
                                  type="password"
                                  autoComplete="new-password"
                                  required
                                  fullWidth
                                  value={formValues.password}
                                  onChange={handleInputChange}
                                />
                              </div>



                            <AutocompleteField
                              label="Zonas"
                              endpoint="/zones/list"
                              value={formValues.zones}
                              onChange={(data: any) => {
                                console.log("data", data);
                                  setFormValues({...formValues, zones: data ? data : []});
                              }}
                              multiple={true}
                            />
                            </div>

                            <div className='input_group'>
                              <h2>Permisos</h2>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={formValues.roles}
                                  label="Reporte"
                                  name="roles"
                                  multiple
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      ["roles"]: e.target.value
                                    });
                                    console.log("roles", e.target.value);
                                  }}
                                >
                                  {roles.map((role: any, index: number) => {
                                    return <MenuItem key={index} value={role.role_id}>
                                      <div className="flex_menuitem">
                                        <span className="menuitem_name">{role.role} 
                                          {role.login_web ? <Chip label="Web" size="small" color="primary" /> : null}
                                          {role.login_app ? <Chip label="App" size="small" color="secondary" /> : null}
                                          
                                          </span>
                                        <span className="menuitem_categories">{role.categories}</span>
                                      </div>
                                    </MenuItem>
                                  })}
                                  
                                </Select>
                              </FormControl>
                            </div>

                            <div className='input_group'>
                            <h2>Bodegas</h2>
                            <div className="warehouseList input_group">
                              {formValues.warehouses?.map((warehouse: any, index: number) => (
                                <div key={index} className="warehouseListItem input_row"> 
                                    <AutocompleteField
                                      label="Bodega"
                                      endpoint="/warehouses/list"
                                      fullwidth
                                      value={warehouse.warehouse_id}
                                      onChange={(data: any) => {
                                        let newWarehouses = [...formValues.warehouses];
                                        newWarehouses[index] = {
                                          ...newWarehouses[index],
                                          warehouse_id: data.value,
                                        };
                                        setFormValues({ ...formValues, warehouses: newWarehouses });
                                      }}
                                    />
                                  <TextField
                                    label="Prioridad"
                                    type="number"
                                    value={warehouse.priority}
                                    onChange={(e) => {
                                      let newWarehouses = [...formValues.warehouses];
                                      newWarehouses[index] = {
                                        ...newWarehouses[index],
                                        priority: e.target.value,
                                      };
                                      setFormValues({ ...formValues, warehouses: newWarehouses });
                                    }}
                                  />
                                  <DeleteOutlineOutlinedIcon 
                                    onClick={() => {
                                      let newWarehouses = [...formValues.warehouses];
                                      newWarehouses.splice(index, 1);
                                      setFormValues({ ...formValues, warehouses: newWarehouses });
                                    }}
                                  />
                                </div>
                              ))}
                              <div className="addWarehouseButton">
                                <IconButton 
                                  aria-label="add" 
                                  onClick={addWarehouse} 
                                  sx={{ 
                                    backgroundColor: "#1C3B41", 
                                    padding: '10px', 
                                    color: '#fff', 
                                    '&:hover': { backgroundColor: '#24545d' } 
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>


                            

                            <div className='input_group'>
                              <h2>Restricciones</h2>
                              <div className="input_row flex_wrap">
                                {/* restrictions.price */}
                                <ISwitch
                                  label="Precio"
                                  checked={formValues.restrictions?.price !== undefined ? formValues.restrictions.price : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        price: e.target.checked,
                                      },
                                    });
                                  }}
                                />

                                {/* restrictions.stock */}
                                <ISwitch
                                  label="Cantidades"
                                  checked={formValues.restrictions?.stock !== undefined ? formValues.restrictions.stock : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        stock: e.target.checked,
                                      },
                                    });
                                  }}
                                />

                                {/* restrictions.category */}
                                <ISwitch
                                  label="Categoría"
                                  checked={formValues.restrictions?.category !== undefined ? formValues.restrictions.category : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        category: e.target.checked,
                                      },
                                    });
                                  }}
                                />

                                {/* restrictions.group */}
                                <ISwitch
                                  label="Grupo"
                                  checked={formValues.restrictions?.group !== undefined ? formValues.restrictions.group : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        group: e.target.checked,
                                      },
                                    });
                                  }}

                                />

                                {/* restrictions.brand */}
                                <ISwitch
                                  label="Marca"
                                  checked={formValues.restrictions?.brand !== undefined ? formValues.restrictions.brand : false}
                                  onChange={(e: any) => {
                                    setFormValues({
                                      ...formValues,
                                      restrictions: {
                                        ...formValues.restrictions,
                                        brand: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                  

                                

                              </div>
                            </div>


                          <div className='input_group'>
                            <h2>Estado</h2>
                            <ISwitch
                              label="Activo"
                              checked={formValues.is_active}
                              name="is_active"
                              onChange={(e: any) => {
                                setFormValues({
                                  ...formValues,
                                  ["is_active"]: e.target.checked
                                });
                              }}
                            />
                          </div>


                        </div>


                      
                    </div>


                    

                </div>
              </div>
          </div>

          <div className="dialogButtons">
            <Button
                onClick={() => setOpenEditor(false)}
                variant="outlined"
                className="button_2"
            >
              Cancelar
            </Button>

            <Button onClick={handleSubmit} variant="contained" className="button_1">Guardar</Button>
          </div>

        </Dialog>
      </>
  );
}
