import { Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AutocompleteField from 'elements/AutocompleteField';
import { useState } from 'react';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DateRange from 'elements/DateRange';
import moment from 'moment';

interface Props {
  open: boolean;
  setOpen: any;
  filters: Array<any>;
  setFilters: any;
}

export default function Filters(props: Props) {
  
  const [tempFilters, setTempFilters] = useState<any>(() => {
    const defaultValues: any = {};
    props.filters.forEach((filter) => {
      if (filter.type === "select") {
        const defaultOption = filter.options.find((opt: any) => opt.default === true);
        if (defaultOption) {
          defaultValues[filter.name] = {
            value: defaultOption.value,
            label: defaultOption.label
          };
        }
      }
    });
    return defaultValues;
  });

  const handleTempFilters = (filter: any, newValue: any, newValueTxt: any) => {
    if (!newValue && newValue !== 0) {
      delete tempFilters[filter.name];
      setTempFilters({ ...tempFilters });
    } else {
      setTempFilters({
        ...tempFilters,
        [filter.name]: {
          value: newValue,
          value_txt: newValueTxt,
          label: filter.label
        }
      });
    }
  };

  //TODAY 
  const today: any = new Date();
  const [selectedDate, handleDateChange] = useState(today);


  //first and last date of the month - remove these lines
  const [date, setDate] = useState({
    startDate: today, // changed from firstDate
    endDate: today, // changed from lastDate
  });

  const validateMandatoryFields = () => {
    const mandatoryFields = props.filters.filter(filter => filter.mandatory);
    for (const field of mandatoryFields) {
      if (!tempFilters[field.name]) {
        alert(`El campo ${field.label} es obligatorio`);
        return false;
      }
    }
    return true;
  };
  
    
  return (
    <Dialog className='filtersDialog' open={props.open} onClose={() => props.setOpen(false)} fullWidth>
      <DialogContent className='forced_box_editor'>
        <h2>Filters</h2>
        <div className='filtersForm'>
          {props.filters && props.filters.map((filter, index) => (
            <div className="filter" key={index}>
              
              {filter.type === "text" && (
                <TextField 
                  variant="outlined"
                  label={filter.label}
                  fullWidth
                  defaultValue={tempFilters[filter.name]?.value || ''}
                  onChange={(e) => {
                    handleTempFilters(filter, e.target.value, e.target.value);
                  }}
                />
              )}
              
              {filter.type === "autocomplete" && (
                <AutocompleteField
                  label={filter.label}
                  endpoint={filter.endpoint}
                  endpoint_params={filter.endpoint_params}
                  value={tempFilters[filter.name]?.value || ''}
                  onChange={(data: any) => {
                    handleTempFilters(filter, data.value, data.label);
                  }}
                  fullWidth
                />
              )}

              {filter.type === "date" && (

                <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDatePicker className='filters_datePicker'
                  label={filter.label}
                  displayStaticWrapperAs="mobile"
                  value={tempFilters[filter.name] || new Date()}
                  showToolbar={false}
                  onChange={(newValue) => {
                    if (newValue) {
                      handleTempFilters(
                        filter,
                        newValue.format('YYYY-MM-DD'),
                        newValue.format('MMM D, YYYY')
                      );
                    }
                    handleDateChange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                </LocalizationProvider>

              )}
              {filter.type === "select" && (

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{filter.label}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tempFilters[filter.name]?.value || ''}
                  label={filter.label}
                  onChange={(e) => {
                    const selectedOption = filter.options.find(
                      (opt: any) => opt.value === e.target.value
                    );
                    handleTempFilters(
                      filter,
                      e.target.value,
                      selectedOption?.label
                    );
                  }}
                >
                  {filter.options.map((option: any, index: number) => (
                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>

             
              )}

              {filter.type === "date_range" && (
                <DateRange
                  className='filters_datePicker'
                  label='Date range'
                  variant="outlined"
                  fullWidth
                  value={{
                    startDate: moment(date.startDate).toDate(),
                    endDate: moment(date.endDate).toDate(),
                  }}
                  format="MMMM DD, yyyy"
                  onChange={(e: any) => {
                    if (e.startDate && e.endDate) {
                      const startDate = moment(e.startDate).format('YYYY-MM-DD');
                      const endDate = moment(e.endDate).format('YYYY-MM-DD');
      
                      setDate({
                        startDate: e.startDate, // Guardar la fecha original sin formatear
                        endDate: e.endDate,    // Guardar la fecha original sin formatear
                      });

                      handleTempFilters(
                        filter,
                        { startDate, endDate },
                        `${startDate} - ${endDate}`
                      );
                    }
                  }}
                  variantDatePicker="side-by-side"
                />
              )}
            </div>
          ))}
        </div>

        <div className='buttons'>
          <Button variant="contained" onClick={() => {
            if (validateMandatoryFields()) {
              props.setFilters(tempFilters)
              props.setOpen(false)
            }
          }}>Apply</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
