import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { ProjectConfig } from 'Global';
import { useSnackbar } from 'notistack';
import 'assets/css/main.css';
import { ApiRequest } from 'GlobalFunctions';

export default function Configuration(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;
  
  const defaultValues: any = {
    quote_note_1: "",
    quote_note_2: "",
    quote_validity: "",
    quote_validity_type: "dias",
    order_note_1: "",
    order_note_2: ""
  };
  
  const [formValues, setFormValues] = useState(defaultValues);
  
  useEffect(() => {
    setLoading(false);
  }, []);
  
  useEffect(() => {
    loadConfiguration();
  }, []);
  
  const loadConfiguration = () => {
    setLoading(true);
    ApiRequest({
      url: '/companies/getConfiguration',
      method: 'get',
      headers: {
        ltkn: localStorage.getItem('ltkn')
      },
      setResponse: (response: any) => {
        console.log("Configuration:", response);
        // Ensure quote_validity_type has a default value if not present
        if (!response.quote_validity_type) {
          response.quote_validity_type = 'dias';
        }
        setFormValues(response);
        setLoading(false);
      },
      error: (err: any) => {
        console.log(err);
        enqueueSnackbar('Error al cargar la configuración', { variant: 'error' });
        setLoading(false);
      }
    });
  };
  
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  
  const handleSubmit = () => {
    setLoading(true);
    
    ApiRequest({
      url: '/companies/updateConfiguration',
      method: 'post',
      headers: {
        ltkn: localStorage.getItem('ltkn'),
      },
      body: {
        configuration: formValues
      },
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('Configuración guardada con éxito', { variant: 'success' });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
      error: (err: any) => {
        console.log(err);
        enqueueSnackbar('Error al guardar la configuración', { variant: 'error' });
        setLoading(false);
      }
    });
  };
  
  return (
    <div id='Configuration' className='ModuleContainer'>
      <div className="ModuleWrapper">
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h2 className="h2_title">Configuración</h2>
            <span className="h2_subtitle">Configure los parámetros de su empresa</span>
          </div>
        </div>
        
        <Box className='box100'>
          <div id="configurationForm">
            <div className='input_group'>
              <h2>Cotizaciones</h2>
              
              <TextField
                id="quote_note_1"
                name="quote_note_1"
                label="Nota de Cotización 1"
                type="text"
                multiline
                rows={3}
                fullWidth
                value={formValues.quote_note_1}
                onChange={handleInputChange}
                margin="normal"
              />
              
              <TextField
                id="quote_note_2"
                name="quote_note_2"
                label="Nota de Cotización 2"
                type="text"
                multiline
                rows={3}
                fullWidth
                value={formValues.quote_note_2}
                onChange={handleInputChange}
                margin="normal"
              />
              
              <Grid container spacing={2} style={{ marginTop: '8px' }}>
                <Grid item xs={6}>
                  <TextField
                    id="quote_validity"
                    name="quote_validity"
                    label="Vigencia de Cotización"
                    type="number"
                    fullWidth
                    value={formValues.quote_validity}
                    onChange={handleInputChange}
                    InputProps={{
                      inputProps: { 
                        min: 0
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="quote-validity-type-label">Tipo de Vigencia</InputLabel>
                    <Select
                      labelId="quote-validity-type-label"
                      id="quote_validity_type"
                      name="quote_validity_type"
                      value={formValues.quote_validity_type}
                      label="Tipo de Vigencia"
                      onChange={handleInputChange}
                    >
                      <MenuItem value="horas">Horas</MenuItem>
                      <MenuItem value="dias">Días</MenuItem>
                      <MenuItem value="semanas">Semanas</MenuItem>
                      <MenuItem value="meses">Meses</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            
            <div className='input_group' style={{ marginTop: '20px' }}>
              <h2>Pedidos</h2>
              
              <TextField
                id="order_note_1"
                name="order_note_1"
                label="Nota de Pedido 1"
                type="text"
                multiline
                rows={3}
                fullWidth
                value={formValues.order_note_1}
                onChange={handleInputChange}
                margin="normal"
              />
              
              <TextField
                id="order_note_2"
                name="order_note_2"
                label="Nota de Pedido 2"
                type="text"
                multiline
                rows={3}
                fullWidth
                value={formValues.order_note_2}
                onChange={handleInputChange}
                margin="normal"
              />
            </div>
            
            <div className="formActions" style={{ marginTop: '20px' }}>
              <Button 
                onClick={handleSubmit} 
                variant="contained" 
                color="primary"
              >
                Guardar Configuración
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
