import { Button, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import DateRange from 'elements/DateRange';
import moment from 'moment';
import { ApiRequest } from 'GlobalFunctions';
import { useSnackbar } from 'notistack';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function ExportClientsDialog({ open, onClose }: Props) {
const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const firstDate = moment().startOf('month').format('YYYY-MM-DD');
  const lastDate = moment().endOf('month').format('YYYY-MM-DD');
  const [date, setDate] = useState({
    startDate: firstDate,
    endDate: lastDate,
  });

  const [loading, setLoading] = useState(false);

  const handleExport = () => {
    setLoading(true);
    ApiRequest({
      url: "/clients/exportNewClients",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        startDate: date.startDate,
        endDate: date.endDate,
      },
      setResponse: (response: any) => {
        if (response.status === "success" && response.fileUrl) {
          downloadFile(response.fileUrl);
        } else {
          enqueueSnackbar('Error al exportar clientes', { variant: 'error' });
        }
        setLoading(false);
        onClose();
      }
    });
  };

  const downloadFile = async (url: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const filename = url.split('/').pop() || 'clientes.txt';
      
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading file:', error);
      enqueueSnackbar('Error al descargar el archivo', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <h2>Exportar Nuevos Clientes</h2>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <DateRange
            label='Rango de fechas'
            variant="outlined"
            fullWidth
            value={date}
            format="MMMM DD, yyyy"
            onChange={(e: any) => {
              if (e.startDate && e.endDate) {
                setDate({
                  startDate: moment(e.startDate).format('YYYY-MM-DD'),
                  endDate: moment(e.endDate).format('YYYY-MM-DD'),
                });
              }
            }}
            variantDatePicker="side-by-side"
          />
        </div>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={onClose}>Cancelar</Button>
          <Button variant="contained" onClick={handleExport}>Exportar</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
