import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Button, Chip, CircularProgress, Dialog, IconButton, Modal, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';
import TuneIcon from '@mui/icons-material/Tune';
import Filters2 from 'elements/Filters/Filters2';
import FiltersApplied2 from 'elements/Filters/FiltersApplied2';
import { TbFileExport } from "react-icons/tb";
import ExportClientsDialog from 'elements/ExportClientsDialog/ExportClientsDialog';

import OrdersEditor from './OrdersEditor';
import { ApiRequest } from 'GlobalFunctions';

export default function OrdersList(props: any) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [filtersValues, setFiltersValues] = useState<any>([]);
  const [open, setOpen] = useState(false);

  const [openEditor, setOpenEditor] = useState(false);
  const [restrictions, setRestrictions] = useState<any>(null);
  const [advancedOrderEditor, setAdvancedOrderEditor] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page, filtersValues])

  // Add auto-refresh interval
  useEffect(() => {
    const interval = setInterval(() => {
      loadList();
    }, 10000); // 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [page, filtersValues]);

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = async () => {

    await ApiRequest({
      url: "/orders/list",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page: page,
        filters: filtersValues,
      },
      setResponse: (response: any) => {
        setList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });
    
    localStorage.setItem('page', page.toString())
}


  const gerRestrictions = async () => {
    await ApiRequest({
      url: "/companies/restrictions",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      setResponse: (response: any) => {
        setRestrictions(response);
        setAdvancedOrderEditor(response.advancedOrderEditor);
      },
    });
  }

  useEffect(() => {
    gerRestrictions();
  }, [])


  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (order_id:number) => {
    setActiveItem(order_id)
    setOpenAlert(true)
  }



  const deleteItem = () => {

    const body = {
      order_id: activeItem
    }

    axios.post(ProjectConfig.api_url + "/orders/deleteItem", body)
      .then((response) => {
        console.log(response.data);
        if(response.data.status === "success"){
          enqueueSnackbar('Pedido eliminado con éxito', { variant: 'success' });
          loadList();
        }
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);

const filters = [
  { type: "date_range", label: "Fecha", name: "date", value: "January 13, 2025 - January 13, 2025", mandatory: true },
  { type: "select", label: "Estado", name: "processed", value: "", options: [{label: "Todos", value: "all"}, {label: "Procesado", value: "1"}, {label: "No procesado", value: "0", default: true} ] },
  { type: "autocomplete", label: "Vendedor", name: "u.user_id", value: "", endpoint: "/users/listByRole", endpoint_params: { role_ids: [7,6,4,3,2] } },
  { type: "text", label: "Zona", name: "zone", value: "" },
  
  { type: "text", label: "Nombre cliente", name: "client_name", value: "" },
  { type: "text", label: "Nombre negocio", name: "business_name", value: "" },
  { type: "text", label: "NIT", name: "document", value: "" },
  
];

const setFilters = (tempFilters: any) => {
  setFiltersValues(tempFilters);
  localStorage.setItem('filters', JSON.stringify(tempFilters));
};

const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
const [downloadFiles, setDownloadFiles] = useState<{ordersFile: string, productsFile: string} | null>(null);

const exportCSV = () => {
  // add validation to check if date filter exists and has values
  if (!filtersValues?.date?.value?.startDate || !filtersValues?.date?.value?.endDate) {
    enqueueSnackbar('Debe seleccionar un rango de fechas', { variant: 'error' });
    return;
  }
  setLoading(true);
  ApiRequest({
    url: "/orders/exportCSV",
    method: "get",
    headers: {
      ltkn: localStorage.getItem("ltkn"),
    },
    query: {
      filters: filtersValues,
    },
    setResponse: (response: any) => {
      if (response.status === "success") {
        setDownloadFiles({
          ordersFile: response.ordersFile,
          productsFile: response.productsFile
        });
        setOpenDownloadDialog(true);
        loadList();
      }
      setLoading(false);
    }
  });
}

const downloadFile = async (url: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = url.split('/').pop() || 'download.txt';
    
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading file:', error);
    enqueueSnackbar('Error al descargar el archivo', { variant: 'error' });
  }
};

const [openExportClients, setOpenExportClients] = useState(false);
  
  return (
      <div id='UsersList' className='ModuleContainer'>
        <div className="ModuleWrapper"> 
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Pedidos</h2>
              <span className="h2_subtitle">Lista de pedidos creados</span>
            </div>
            
            <div className='module_tools'>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<TuneIcon />}
              onClick={() => setOpen(true)}
            >
              Filtrar
            </Button>

            <Button
              variant="contained"
              color="secondary"
              
              startIcon={<TbFileExport />}
              onClick={() => {
                exportCSV()
              }}
            >
              Exportar Pedidos
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<TbFileExport />}
              onClick={() => setOpenExportClients(true)}
            >
              Exportar Nuevos Clientes
            </Button>
          </div>
          
            <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null)
                setOpenEditor(true)
              }}
              startIcon={<AddIcon />}
            >
              Crear pedido
            </Button>

            </div>
            
          </div>


          

          <Filters2 open={open} setOpen={setOpen} filters={filters} setFilters={setFilters} />
          <FiltersApplied2 filtersValues={filtersValues} setFiltersValues={setFiltersValues} filters={filters} />

          <div id='UsersListIn'>


            
              <Box id='WarehouseList' className='box100'>
              <TableContainer>
              <Table aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>ID</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Cliente</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Zona</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Vendedor</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Tipo</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Fecha / HORA</TableCell>
                    <TableCell sx={{color:'#a8a8a8', borderBottom:"none"}}>Procesado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item,i) => {

                      const date = moment(item.date).format('DD/MM/YYYY');
                      const time = moment(item.date).format('HH:mm');

                      return(
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >        

                      <TableCell>
                        {item.order_id}
                      </TableCell>              

                        <TableCell>
                        <span className='flex_title'>{item.client_name + " " + item.client_last_name}</span>
                        <span className='flex_subtitle'>{item.business_name}</span>
                        </TableCell>

                        <TableCell>
                          <span className='flex_title'>{item.zone_name}</span>
                          <span className='flex_subtitle'>{item.zone_code}</span>
                        </TableCell>

                        <TableCell>
                          {item.user_name}
                        </TableCell>  

                        <TableCell>
                          <Chip
                            label={item.order_type_label}
                            size='small'
                            sx={{
                              backgroundColor:
                                item.order_type === 'order'
                                  ? '#1976d2' // primary blue
                                  : item.order_type === 'quote'
                                    ? '#e7a42a' // orange
                                    : item.order_type === 'undelivered'
                                      ? '#cd3131' // red
                                      : undefined,
                              color: '#fff',
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <span className='flex_title'>{date}</span>
                          <span className='flex_subtitle'>{time}</span>
                        </TableCell>

                        <TableCell>
                          {item.order_type === 'order' && (
                            <Chip
                              label={item.processed}
                              size='small'
                              sx={{
                                backgroundColor: item.processed === 'Procesado' ? 'success.main' : 'grey.500',
                                color: '#fff',
                              }}
                            />
                          )}
                        </TableCell>

                        <TableCell align="right">


                          {item.order_type !== 'undelivered' &&
                            <Button 
                              size="small"
                              variant="contained"
                              color="primary"
                              style={{"marginRight": "10px"}}
                              startIcon={<ModeEditIcon />}
                              onClick={() => {
                                setActiveItem(item.order_id)
                                setOpenEditor(true)
                              }}
                            >
                              Detalle
                            </Button>
                          }


                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => handleDelete(item.order_id)}
                          >
                            Borrar
                          </Button>
                      </TableCell>
                      </TableRow>
                    )})}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            </Box>
  
          </div>
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar este pedido? Esta acción no se puede deshacer."
            send={deleteItem}
        />



        <OrdersEditor
          setLoading={setLoading}
          openEditor={openEditor} 
          setOpenEditor={setOpenEditor}
          order_id={activeItem} 
          loadList={loadList}
          handleClose={() => setOpenEditor(false)}
          advancedOrderEditor={advancedOrderEditor}
        />

        <Dialog
          open={openDownloadDialog}
          onClose={() => setOpenDownloadDialog(false)}
          aria-labelledby="download-dialog-title"
        >
          <div style={{ padding: '20px' }}>
            <h2 id="download-dialog-title" style={{ marginBottom: '20px' }}>Descargar archivos exportados</h2>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => downloadFiles?.ordersFile && downloadFile(downloadFiles.ordersFile)}
              >
                Descargar Pedidos
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => downloadFiles?.productsFile && downloadFile(downloadFiles.productsFile)}
              >
                Descargar Productos
              </Button>
            </div>
          </div>
        </Dialog>

        <ExportClientsDialog 
          open={openExportClients}
          onClose={() => setOpenExportClients(false)}
        />

    </div>
  );
};