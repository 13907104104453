
import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";
import { ApiRequest } from "GlobalFunctions";

export default function TypologyEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, typology_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    typology_id: typology_id ? typology_id : "null",
    typology_name: "",
    typology_code: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (typology_id) {
      axios.get(`${ProjectConfig.api_url}/typology/details`, {
        params: { typology_id },
      })
      .then((response) => {
        if(response.data.status === "success"){
          setFormValues(response.data.data);
        }
      })
      .catch((err) => console.log(err.response));
    } else {
      setFormValues(defaultValues);
    }
  }, [typology_id]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));

    await ApiRequest({
      url: "/typology/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar('Tipología guardada con éxito', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" style={{ maxWidth: '502px' }}>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Tipología</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">
            <TextField
              id="typology_name"
              name="typology_name"
              label="Nombre de la Tipología"
              type="text"
              required
              fullWidth
              value={formValues.typology_name}
              onChange={handleInputChange}
            />

            <TextField
              id="typology_code"
              name="typology_code"
              label="Código de la Tipología"
              type="text"
              required
              fullWidth
              value={formValues.typology_code}
              onChange={handleInputChange}
            />
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}